// This hook returns an object that toggles on and off
// the analytics tabs on the homepage.
import { useRouteAccess } from 'src/routes/routeAccess';

// the analytics tabs on the homepage.
export function useAnalyticsAccess() {
  const routeAccess = useRouteAccess();

  return {
    CLIENT: true,
    CLIENT_ACTIVATION: true,
    SUBSCRIPTION: routeAccess.allowPaymentsPage,
  } as const;
}
