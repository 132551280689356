import Stripe from 'stripe';
import moment from 'moment';
import { CurrencyFormatter } from 'src/components/Formatters';
import {
  BILLING_INTERVALS,
  LEGACY_BILLING_PLANS,
  SubscriptionStatus,
  ACTIVE_BILLING_PLANS,
} from 'src/constants';
import { OnboardingStatus } from 'src/entities/OnboardingStatus';

export const getReadableAmountForPrice = (price?: Stripe.Price) => {
  if (!price) {
    return '';
  }
  const months = price.recurring?.interval === BILLING_INTERVALS.YEAR ? 12 : 1;
  return CurrencyFormatter({
    value: price.unit_amount ? price.unit_amount / (100 * months) : 0,
  });
};

export const getAvilablePlans = (prices: Stripe.Price[]) =>
  prices.map((priceItem) => ({
    ...priceItem,
    id: priceItem.id as LEGACY_BILLING_PLANS,
    readableAmount: getReadableAmountForPrice(priceItem),
  }));

/**
 * Check if plan is a trial plan which is true when id is portal_register or portal_trial
 * @param price stripe price item to check
 */
export const isTrialPlan = (price?: Stripe.Price) =>
  price?.id === 'portal_register' || price?.id === ACTIVE_BILLING_PLANS.TRIAL;

/**
 * Returns active trial subscription from list of subscriptions
 * @param subscriptions list of all subscriptions
 */
export const activeTrialSubscription = (
  subscriptions?: Stripe.Subscription[],
) =>
  subscriptions?.find(
    (subscription) =>
      subscription.items?.data[0]?.plan.id === ACTIVE_BILLING_PLANS.TRIAL &&
      subscription.status === SubscriptionStatus.Trialing,
  );

/**
 * Check if subscriptions array has an active trial
 * @param subscriptions list of all subscriptions
 */
export const hasActiveTrial = (subscriptions?: Stripe.Subscription[]) => {
  const trialSubscription = activeTrialSubscription(subscriptions);

  return isTrialPlan(trialSubscription?.items?.data[0].price);
};

/**
 * Checks if trial period is expired
 * @param subscription: actual plan subscription
 * @returns
 */
export const isTrialPeriodExpired = (
  subscriptions?: Stripe.Subscription[] | undefined,
) => {
  const trialSubscriptions = subscriptions?.filter(
    (subscription) =>
      subscription.items?.data[0]?.plan.id === ACTIVE_BILLING_PLANS.TRIAL,
  );

  // When trial subscription ends status change to Active by default from stripe
  // We explicitly change status to cancelled from backend
  // Handling both the cases if somehow backend is not able to change status to cancelled
  const cancelledTrialSubscriptions = subscriptions?.filter(
    (subscription) =>
      subscription.items?.data[0]?.plan.id === ACTIVE_BILLING_PLANS.TRIAL &&
      (subscription.status === SubscriptionStatus.Canceled ||
        subscription.status === SubscriptionStatus.Active),
  );
  const trialEnd = trialSubscriptions?.at(0)?.trial_end;
  return (
    (Boolean(
      trialSubscriptions?.length &&
        trialEnd &&
        trialEnd &&
        new Date() > new Date(trialEnd * 1000),
    ) ||
      Boolean(
        cancelledTrialSubscriptions && cancelledTrialSubscriptions.length > 0,
      )) &&
    !hasActiveTrial(subscriptions)
  );
};

/**
 * Check if plan is an access plan that is not
 * per user
 * @param price stripe price item to check
 */
export const isFixedAccessPlan = (price?: Stripe.Price) => {
  const currentPlanId = price?.id;

  return Boolean(
    currentPlanId && currentPlanId.startsWith('portal') && !isTrialPlan(price),
  );
};

/**
 * Check if plan is an enterprise plan
 * @param price stripe price item to check
 */
export const isEnterprisePlan = (price?: Stripe.Price) => {
  const currentPlanId = price?.id;

  return Boolean(currentPlanId && currentPlanId.endsWith('ent'));
};

/**
 * Check if plan is a pro plan
 * @param price stripe price item to check
 */
export const isProPlan = (price?: Stripe.Price) => {
  const currentPlanId = price?.id;

  return Boolean(currentPlanId && currentPlanId.includes('pro'));
};

/**
 * Check if plan is a basic plan
 * @param price stripe price item to check
 */
export const isBasicPlan = (price?: Stripe.Price) => {
  const currentPlanId = price?.id;

  return Boolean(
    currentPlanId &&
      (currentPlanId.includes('basic') || currentPlanId.includes('standard')),
  );
};

/**
 * Check if plan is a starter plan
 * @param price stripe price item to check
 */
export const isStarterPlan = (price?: Stripe.Price) => {
  const currentPlanId = price?.id;

  return Boolean(currentPlanId && currentPlanId.startsWith('starter'));
};

/**
 * Check if plan is a professional plan
 * @note - copilot professional plan is different from legacy pro plan
 * @param price stripe price item to check
 */
export const isCopilotProfessionalPlan = (price?: Stripe.Price) => {
  const currentPlanId = price?.id;

  return Boolean(currentPlanId && currentPlanId.startsWith('professional'));
};

/**
 * Check if plan is a starter plan
 * @param price stripe price item to check
 */
export const isAdvancedPlan = (price?: Stripe.Price) => {
  const currentPlanId = price?.id;

  return Boolean(currentPlanId && currentPlanId.startsWith('advanced'));
};

/**
 * Check if plan is a fixed access pro plan
 * @param price stripe price item to check
 */
export const isFixedAccessProPlan = (price?: Stripe.Price) =>
  isProPlan(price) && isFixedAccessPlan(price);

/**
 * Check if plan is a fixed access basic plan
 * @param price stripe price item to check
 */
export const isFixedAccessBasicPlan = (price?: Stripe.Price) =>
  isBasicPlan(price) && isFixedAccessPlan(price);

/**
 * Returns billing recurring interval for price
 * @param price stripe price item to check
 */
export const getBillingCycle = (price?: Stripe.Price) =>
  price?.recurring?.interval;

export const getTrialDaysRemainingText = ({
  subscription,
  onboardingTaskStatus,
}: {
  subscription?: Stripe.Subscription;
  onboardingTaskStatus?: OnboardingStatus;
}) => {
  if (!subscription || !subscription.trial_end) {
    return '';
  }
  const endDate = moment.unix(subscription.trial_end);
  const startDate = moment();
  const difference = Math.ceil(endDate.diff(startDate, 'days', true));
  const daysLeft =
    difference === 1 ? `${difference} day` : `${difference} days`;

  let trialDaysRemainingText = '';

  if (difference <= 0) {
    trialDaysRemainingText = `Your trial is over`;
  }
  if (difference > 12) {
    trialDaysRemainingText = 'Your trial has just started';
  }

  trialDaysRemainingText = `You have ${daysLeft} left of your trial`;

  if (onboardingTaskStatus?.fields.creditsAwarded) {
    trialDaysRemainingText += ' and you have a $100 credit';
  }

  return trialDaysRemainingText;
};

/**
 * Get active subscription from all subscriptions
 * @param subscriptions list of all subscriptions
 */
export const activeSubscriptions = (subscriptions?: Stripe.Subscription[]) =>
  subscriptions?.filter(
    (subscription) =>
      subscription.items.data[0].plan.id !== ACTIVE_BILLING_PLANS.TRIAL &&
      (subscription.status === SubscriptionStatus.Active ||
        subscription.status === SubscriptionStatus.Unpaid ||
        subscription.status === SubscriptionStatus.PastDue),
  );

/**
 * Checks if there is any cancelled subscription (ignores trial subscription)
 * @param subscriptions list of all subscriptions
 */
export const hasCancelledPlan = (subscriptions?: Stripe.Subscription[]) => {
  const cancelledSubscriptions = subscriptions?.filter(
    (subscription) =>
      subscription.items.data[0].plan.id !== ACTIVE_BILLING_PLANS.TRIAL &&
      subscription.status === SubscriptionStatus.Canceled,
  );

  return (
    Boolean(cancelledSubscriptions && cancelledSubscriptions.length > 0) &&
    !hasActiveTrial(subscriptions)
  );
};

/**
 * Checks what respective configurations of portal plan data can disable portal badge
 * @param data with properties isPro, isLegacyPaidPlan and planType = 'starter', 'professional', 'advanced', 'enterprise'
 */
export const canDisablePortalBadge = (data: {
  isPro: boolean;
  isLegacyPaidPlan: boolean;
  planType: string;
}) => {
  const { isPro, isLegacyPaidPlan, planType } = data;
  return (
    (isPro && isLegacyPaidPlan) ||
    planType === 'enterprise' ||
    planType === 'advanced'
  );
};
