import { AxiosResponse } from 'axios';
import { FormikErrors } from 'formik';
import { AuthStateTypes } from 'src/constants/authConsts';

export const CONTROL_LOGIN_MODAL = 'CONTROL_LOGIN_MODAL';
export const SNACKBAR_ALERT = 'SNACKBAR_ALERT';
export const CLEAR_SNACKBAR_ALERT = 'CLEAR_SNACKBAR_ALERT';
export const TOGGLE_PRIMARY_SIDEBAR_MOBILE = 'TOGGLE_PRIMARY_SIDEBAR_MOBILE';
export const TOGGLE_SECONDARY_SIDEBAR = 'TOGGLE_SECONDARY_SIDEBAR';
export const TOGGLE_RIGHT_SIDEBAR = 'TOGGLE_RIGHT_SIDEBAR';
export const CLEAR_PAGE_CHANGES = 'CLEAR_PAGE_CHANGES';
export const SET_PAGE_CHANGES = 'SET_PAGE_CHANGES';
export const UN_SET_PAGE_CHANGES = 'UN_SET_PAGE_CHANGES';
export const SET_FORM_PAGE_CHANGED = 'SET_FORM_PAGE_CHANGED';
export const SET_SETTINGS_MODAL_FORM_PAGE_CHANGED =
  'SET_SETTINGS_MODAL_FORM_PAGE_CHANGED';
export const SET_CAN_SUBMIT_FORM_PAGE = 'SET_CAN_SUBMIT_FORM_PAGE';
export const SET_CAN_RESET_SETTINGS_FORM_PAGE =
  'SET_CAN_RESET_SETTINGS_FORM_PAGE';
export const SET_PAGE_CHANGE_ERRORS = 'SET_PAGE_CHANGE_ERRORS';
export const PAGE_RELOADING = 'PAGE_RELOADING';
export const SET_PROGRESS_VALUE = 'SET_PROGRESS_VALUE';
export const COMPLETE_PROGRESS = 'COMPLETE_PROGRESS';
export const RESET_PROGRESS_VALUE = 'RESET_PROGRESS_VALUE';
export const TOGGLE_AUTO_SIGNIN = 'TOGGLE_AUTO_SIGNIN';
export const UPDATE_DETAILS_SIDEBAR = 'UPDATE_DETAILS_SIDEBAR';
export const UPDATE_SEARCH_VALUE = 'UPDATE_SEARCH_VALUE';
export const ENABLE_NATIVE_FIND = 'ENABLE_NATIVE_FIND';
export const SET_BANNER_DATA = 'SET_BANNER_DATA';
export const CLEAR_BANNER_DATA = 'CLEAR_BANNER_DATA';
export const TOGGLE_SETTINGS_MODAL = 'TOGGLE_SETTINGS_MODAL';
export const TOGGLE_HELP_CENTER_MODAL = 'TOGGLE_HELP_CENTER_MODAL';
export const TOGGLE_SETTINGS_MODAL_MOBILE_DRAWER =
  'TOGGLE_SETTINGS_MODAL_MOBILE_DRAWER';
export const SET_ACTIVE_SETTINGS_PAGE = 'SET_ACTIVE_SETTINGS_PAGE';
export const SET_SECONDARY_SIDEBAR_HIDDEN = 'SET_SECONDARY_SIDEBAR_HIDDEN';
export const SAVE_BACK_TO_PORTAL_PAGE = 'SAVE_BACK_TO_PORTAL_PAGE';
export const SET_AGREE_PLAN_CANCEL_TERMS = 'SET_AGREE_PLAN_CANCEL_TERMS';

export enum AlertVariant {
  ERROR = 'error',
  SUCCESS = 'success',
  PROGRESS = 'progress',
  INFO = 'info',
  WARNING = 'warning',
}

export interface PrimarySideBarMobileType {
  isOpen: boolean;
}
export interface SecondarySideBarType {
  isOpen?: boolean;
  info?: string;
  hidden?: boolean; // used to track if secondary sidebar is rendered at all
}

export interface RightSideBarType {
  isOpen: boolean;
}

// generic state type for openable/closable element
export interface TemporaryViewType {
  isOpen: boolean;
}

export type HelpCenterModalState = TemporaryViewType;
export interface ChangeModel {
  name: string;
  value: any;
}

export interface PageChangeForm {
  allowSignup: boolean;
  portalName: string;
  websiteTitle: string;
  metaDescription: string;
  logoUrl: string;
  iconUrl: string;
  signInUrl: string;
  socialImageUrl: string;
  portalColor: string;
}

export interface BannerOptions {
  message: string;
  buttonOptions?: {
    label: string;
    path: string;
  };
}

export interface UiState {
  authType: AuthStateTypes | null;
  nextPath: string;
  alertMessageType?: AlertVariant;
  alertMessage: string;
  primarySideBarMobile: PrimarySideBarMobileType;
  secondarySideBar: SecondarySideBarType;
  pageChanges: ChangeModel[];
  pageChangeErrors: FormikErrors<PageChangeForm>;
  helpCenterModal: HelpCenterModalState;
  isFormPageChanged: boolean;
  isReloading: boolean;
  progressValue: number;
  isProgressComplete: boolean;
  isAutoSignIn: boolean;
  rightSideBar: RightSideBarType;
  searchValue: string;
  enableNativeFind: boolean;
  bannerOptions?: BannerOptions;
  canSubmitFormPage: boolean;
  backToPortalDestinationPath: string;
  agreePlanCancelTerms: boolean;
}

export interface AuthAction {
  type: typeof CONTROL_LOGIN_MODAL;
  payload: {
    authType: AuthStateTypes;
    nextPath?: string;
  };
}

export interface AlertAction {
  type: typeof SNACKBAR_ALERT;
  alertMessage: string;
  alertMessageType: string;
}

export interface ClearAlertAction {
  type: typeof CLEAR_SNACKBAR_ALERT;
}

export interface AlertMessageModel {
  axiosError?: AxiosResponse;
  successMessage?: string;
  errorMessage?: string;
  actionMessage?: string;
  progressMessage?: string;
}

export interface TogglePrimarySidebarMobile {
  type: typeof TOGGLE_PRIMARY_SIDEBAR_MOBILE;
  payload: PrimarySideBarMobileType;
}

export interface ToggleSecondarySidebar {
  type: typeof TOGGLE_SECONDARY_SIDEBAR;
  payload: SecondarySideBarType;
}

export interface SetSecondarySidebarHiddenActionType {
  type: typeof SET_SECONDARY_SIDEBAR_HIDDEN;
  payload: boolean;
}

export interface ToggleRightSidebar {
  type: typeof TOGGLE_RIGHT_SIDEBAR;
  payload: RightSideBarType;
}

export interface ClearPageChangesAction {
  type: typeof CLEAR_PAGE_CHANGES;
}

export interface SetPageChangesAcion {
  type: typeof SET_PAGE_CHANGES;
  payload: ChangeModel[];
}

export interface UnSetPageChangesAcion {
  type: typeof UN_SET_PAGE_CHANGES;
  payload: string[];
}

export interface SetFormPageChangesAcion {
  type: typeof SET_FORM_PAGE_CHANGED;
  payload: boolean;
}
export interface SetPageChangeErrorsAcion {
  type: typeof SET_PAGE_CHANGE_ERRORS;
  payload: FormikErrors<PageChangeForm>;
}
export interface SetPageReloadingAcion {
  type: typeof PAGE_RELOADING;
}

export interface SetProgressValueAction {
  type: typeof SET_PROGRESS_VALUE;
  payload: number;
}

export interface CompleteProgressAction {
  type: typeof COMPLETE_PROGRESS;
}

export interface ResetProgressValueAction {
  type: typeof RESET_PROGRESS_VALUE;
}

export interface ToggleAutoSignInAction {
  type: typeof TOGGLE_AUTO_SIGNIN;
  payload: boolean;
}

export interface UpdateSearchValueActionType {
  type: typeof UPDATE_SEARCH_VALUE;
  payload: string;
}

export interface EnableNativeFindValueActionType {
  type: typeof ENABLE_NATIVE_FIND;
  payload: boolean;
}

export interface ShowBannerActionType {
  type: typeof SET_BANNER_DATA;
  payload: BannerOptions;
}

export interface ClearBannerActionType {
  type: typeof CLEAR_BANNER_DATA;
}

export interface ToggleHelpCenterModalType {
  type: typeof TOGGLE_HELP_CENTER_MODAL;
  payload: boolean;
}

export interface SetCanSubmitFormPage {
  type: typeof SET_CAN_SUBMIT_FORM_PAGE;
  payload: boolean;
}

export interface SetCanResetSettingsFormPage {
  type: typeof SET_CAN_RESET_SETTINGS_FORM_PAGE;
  payload: boolean;
}

export interface SaveBackToPortalPageAction {
  type: typeof SAVE_BACK_TO_PORTAL_PAGE;
  payload: string;
}

export interface SetAgreePlanCancelTermsAction {
  type: typeof SET_AGREE_PLAN_CANCEL_TERMS;
  payload: boolean;
}

export type AuthActionTypes =
  | AuthAction
  | AlertAction
  | ClearAlertAction
  | TogglePrimarySidebarMobile
  | ToggleSecondarySidebar
  | ToggleRightSidebar
  | ClearPageChangesAction
  | SetPageChangesAcion
  | UnSetPageChangesAcion
  | SetFormPageChangesAcion
  | SetPageChangeErrorsAcion
  | SetPageReloadingAcion
  | SetProgressValueAction
  | CompleteProgressAction
  | ResetProgressValueAction
  | ToggleAutoSignInAction
  | UpdateSearchValueActionType
  | EnableNativeFindValueActionType
  | ShowBannerActionType
  | ClearBannerActionType
  | ToggleHelpCenterModalType
  | SetCanSubmitFormPage
  | SetCanResetSettingsFormPage
  | SetSecondarySidebarHiddenActionType
  | SaveBackToPortalPageAction
  | SetAgreePlanCancelTermsAction;
