import React, { useContext } from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  LinearProgress,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { TasksList } from 'src/components/HomePage/OnboardingTasksSection/TasksList';
import { TaskDetails } from 'src/components/HomePage/OnboardingTasksSection/TaskDetails';
import { RegularCardBox } from 'src/components/Cards/RegularCardBox';
import { usePatchDismissalStateMutation } from 'src/services/api/improvedOnboardingApi';
import { AlertSnackbar } from 'src/components/AlertSnackbar/AlertSnackbar';
import { AlertVariant } from 'src/store/ui/types';
import { OnboardingStatus } from 'src/entities/OnboardingStatus';
import { MapTaskCodeToTaskDetails } from 'src/components/HomePage/OnboardingTasksSection/helper';
import { SectionHeader } from 'src/components/HomePage/OnboardingTasksSection/SectionHeader';
import { CopilotGreen, CopilotLightGreen } from 'src/theme/colors';
import { TaskCodes } from 'src/components/HomePage/OnboardingTasksSection/constants';
import { FlagsContext } from 'src/context';
import BaseTypography from 'src/components/Text/BaseTypography';
interface OnboardingTasksSectionProps {
  onboardingStatus: OnboardingStatus;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    flexContainer: {
      display: 'flex',
      minHeight: 230,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    improvedOnboardingCard: {
      height: '100px',
    },
    sectionHeaderWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(0.5, 0),
    },
    linearProgressFilled: {
      backgroundColor: CopilotGreen,
      borderRadius: theme.spacing(0.25, 0.25, 0, 0),
    },
    linearProgressUnFilled: {
      backgroundColor: CopilotLightGreen,
      borderRadius: theme.spacing(0.25, 0.25, 0, 0),
    },
  }),
);

export const OnboardingTasksSection: React.FC<OnboardingTasksSectionProps> = ({
  onboardingStatus,
}) => {
  const classes = useStyles();
  const [patchDismissalState] = usePatchDismissalStateMutation();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { EnableNewIUHome } = useContext(FlagsContext);

  const initialSelectedTask = onboardingStatus.additionalFields.tasks?.find(
    (t) => !t.done,
  );
  const [activeTaskCode, setActiveTaskCode] = React.useState<
    TaskCodes | undefined
  >(initialSelectedTask?.taskCode);

  const activeTaskDetails = React.useMemo(
    () =>
      activeTaskCode
        ? {
            ...MapTaskCodeToTaskDetails(activeTaskCode),
          }
        : null,
    [activeTaskCode],
  );

  // This is to handle the scenario if active task gets marked done and WSS event changes the status
  // of active task to done. In that scenario we want to select first not done task from the task list
  React.useEffect(() => {
    const selectedTask = onboardingStatus.additionalFields.tasks?.find(
      (t) => t.taskCode === activeTaskCode,
    );
    if (selectedTask?.done) {
      const firstNotDoneTask = onboardingStatus.additionalFields.tasks?.find(
        (t) => !t.done,
      );
      setActiveTaskCode(firstNotDoneTask?.taskCode);
    }
  }, [onboardingStatus]);

  const handleSelectTask = (code: TaskCodes) => {
    setActiveTaskCode(code);
  };

  const handleUndoDismiss = async () => {
    await patchDismissalState({
      fields: { dismissed: false },
    });
  };

  const successDismissSnackbar = React.useCallback(
    (snackbarKey: string) => (
      <div key={snackbarKey}>
        <AlertSnackbar
          variant={AlertVariant.SUCCESS}
          onClose={() => closeSnackbar(snackbarKey)}
          alertMessage="Card dismissed"
          onAction={handleUndoDismiss}
          actionLabel="Undo"
        />
      </div>
    ),
    [closeSnackbar],
  );

  const handleDismissImprovedOnboarding = async () => {
    const onboardingStatusUpdated = await patchDismissalState({
      fields: { dismissed: true },
    }).unwrap();

    if (onboardingStatusUpdated.fields.dismissed) {
      // show snackbar to allow re-enabling
      enqueueSnackbar('Card has been dismissed', {
        content: (snackbarKey: string) => successDismissSnackbar(snackbarKey),
      });
    }
  };

  // if there are not task present then no need to show setup onboarding section
  if (
    !activeTaskDetails ||
    !activeTaskCode ||
    onboardingStatus.additionalFields.tasks?.length === 0 ||
    onboardingStatus.fields.dismissed ||
    onboardingStatus.fields.creditsAwarded
  ) {
    return null;
  }

  const sectionItems = (
    <>
      <RegularCardBox onDismissClick={handleDismissImprovedOnboarding}>
        <div className={classes.topContainer}>
          <LinearProgress
            variant="determinate"
            value={onboardingStatus.additionalFields.completion}
            classes={{
              root: classes.linearProgressUnFilled,
              bar: classes.linearProgressFilled,
            }}
          />
          <div className={classes.flexContainer}>
            <TasksList
              onboardingStatus={onboardingStatus}
              activeTaskCode={activeTaskCode}
              onSelect={handleSelectTask}
            />
            <TaskDetails taskData={activeTaskDetails} />
          </div>
        </div>
      </RegularCardBox>
    </>
  );

  return EnableNewIUHome ? (
    <div>
      <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center text-black-heading pb-[16px] ">
        <BaseTypography fontType="16Medium">
          Finish onboarding and receive a $100 credit
        </BaseTypography>
        <BaseTypography
          data-testid="section-subtitle"
          className="text-secondary"
          fontType="13Medium"
        >
          {onboardingStatus.additionalFields.completion}% completed
        </BaseTypography>
      </div>

      <div className="flex flex-col gap-[20px]">{sectionItems}</div>
    </div>
  ) : (
    <SectionHeader
      title="Welcome to Copilot"
      subTitle="Complete the steps below and get a $100 credit added to your account"
      description="Become an expert"
      descriptionDetail={`${onboardingStatus.additionalFields.completion}% completed`}
    >
      {sectionItems}
    </SectionHeader>
  );
};
